import React, { useState, useEffect } from 'react';

interface CountdownTimerProps {
  targetDate: Date;
}

const CountdownComponent: React.FC<CountdownTimerProps> = ({ targetDate }) => {
  const calculateTimeRemaining = () => {
    const now = new Date().getTime();
    const difference = targetDate.getTime() - now;

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return {
      days,
      hours,
      minutes,
      seconds,
    };
  };

  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  useEffect(() => {
    const updateCountdown = () => {
      setTimeRemaining(calculateTimeRemaining());
    };

    const countdownInterval = setInterval(updateCountdown, 1000);

    return () => {
      clearInterval(countdownInterval);
    };
  }, [targetDate]);

  const isCountdownOver = timeRemaining.days <0 && timeRemaining.hours <0 && timeRemaining.minutes <0 && timeRemaining.seconds <0;

  return (
    <div className={isCountdownOver ? 'd-none' : ''}>
      <p style={{marginBottom: "0px", fontWeight: "400", fontSize: "2rem", color: "#DF2A26"}}>E' solo questione di ore!</p>
      <p style={{marginBottom: "0px", fontWeight: "900"}}>Se sei interessato, inserisci la tua email e ti contatteremo appena sarà disponibile per il download.</p>
      
      {/* <span style={{fontWeight: "900", color: "#DF2A26"}}>{timeRemaining.hours} <span style={{fontWeight: "400", color: "#08080A"}}>Ore</span> </span>
      <span  style={{fontWeight: "900", color: "#DF2A26"}}>{timeRemaining.minutes} <span style={{fontWeight: "400", color: "#08080A"}}>Minuti</span> </span>
      <span  style={{fontWeight: "900", color: "#DF2A26"}}>{timeRemaining.seconds} <span style={{fontWeight: "400", color: "#08080A"}}>Secondi</span> </span> */}
    </div>
  );
};

export default CountdownComponent;