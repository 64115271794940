import React, { Fragment, useEffect } from "react";
import InstagramIcon from "./../imeges/instagram_icon.svg";
import FacebookIcon from "./../imeges/facebook_icon.svg";
import logoImage from "./../imeges/logo_handsoff.svg";
import { useLocation } from "react-router-dom";









const HeaderComponent: React.FC = () => {

  

  // voglio che i nav-item siano attivi quando lo scroll è nella sezione corrispondente sul componente Home con id = "use_case" e "pricing"   
  const location = useLocation();

  // Access location properties
  const { pathname, search, hash } = location;
  useEffect(() => {
    const currentPathHash = hash;
    const currentPathPathname = pathname;  
    const navLinks = document.querySelectorAll(".nav-link");
    navLinks.forEach((link) => {
      if (link.getAttribute("href") === currentPathPathname + currentPathHash) {
        link.classList.add("active");
        // voglio scrollare alla sezione corrispondente nel componente Home
        const element = document.getElementById(currentPathHash.replace("#", ""));
        if (element) {
          element.scrollIntoView();
        }

      } else {
        link.classList.remove("active");
      }
    });
  }, [pathname, hash]);




  
  

  

  

  

  return (

    <>

    <div style={{position: "fixed", top: "0", left: "0", right: "0", zIndex: "1000", backgroundColor: "#E4E3D8", }}>
    <div style={{backgroundColor: "#F2F1E5", borderRadius: "0px 0px 20px 20px"}} className="container">
      <div className="row p-2">
        <div className="col"><span>Info e assistenza: +39 0746-257060  -  info@agesic.com</span></div>
        <div className="col"><div style={{float: "right"}}><a title="Instagram" href="#"><img className="me-2 d-none" src={InstagramIcon} alt="Instagram link"></img></a><a title="Facebook" href="https://www.facebook.com/agesic.handsoff/" target="blank"><img src={FacebookIcon} alt="Fabebook link"></img></a></div></div>
      </div>
    </div>

    

    <div style={{backgroundColor: "#E4E3D8"}} className="container">
        <div className="row">
            <div className="col-12">
            <nav className="navbar navbar-expand-lg navbar-light">
      <a title="Home page" className="navbar-brand" href="/">
        <img src={logoImage} alt="aGesic HandsOff Brand Logo" width="150" className="d-inline-block align-text-top"></img>
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ms-auto">
          
          <li className="nav-item">
            <a title="Casi d'uso di aGesic HandsOff" className="nav-link" href="/#use_case">
              Casi d'uso
            </a>
          </li>
          
          <li className="nav-item">
            <a title="Prezzi del servizio aGesic HandsOff" className="nav-link" href="/#pricing">
             Prezzi
            </a>
          </li>
          <li className="nav-item">
            <a title="aGesic mOve dispositivo iOt" className="nav-link" href="/move">
             mOve
            </a>
          </li>
         
        </ul>
        
      </div>
    </nav>
            </div>
        </div>
        </div>
    </div>
   

  
    
    </>
    
    
  );
};

export default HeaderComponent;
