import React, { useEffect } from "react";
import VideoComponent from "../components/VideoComponent";
import phone from "../imeges/app+move.svg";
import googlePlay from "../imeges/google_play_badge.svg";
import AppStore from "../imeges/app_store_ITA.svg"
import UseCaseSectionComponent from "../components/UseCaseSectionComponent";
import PricingComponent from "../components/PricingComponent";
import CountdownComponent from "../components/CountDownComponent";
import NewsletterComponent from "../components/NewsletterComponent";



const HomePage: React.FC = () => {


    useEffect(() => {
        // Cambia il titolo della pagina quando il componente è montato
        document.title = 'aGesic HandsOFF. Giù le mani! Una app per la tua sicurezza.';
    
        // Pulisci il titolo quando il componente viene smontato
        return () => {
          document.title = 'aGesic HandsOFF';
        };
      }, []);

      const targetDate = new Date('November 25, 2023 18:58:00');

    
   

    return (
        <>
        <div className="container mt-5">
            <div className="row justify-content-center align-content-center">
                <div className="col-lg-4 text-center align-self-center">
                    <figure>
                    <img alt="Il sistema aGesic HandsOff" className="img-fluid" src={phone}></img>
                        
                    </figure>
                    
                    
                </div>
                <div className="col-lg-8 align-self-center text-center">
                
                    <VideoComponent />
                    <h1 style={{fontWeight: "900"}}>Il futuro della sicurezza personale con <br></br> <span style={{color: "#2264AE", fontWeight: "400"}}>aGesic</span> <span style={{color: "#DF2A26", fontWeight: "400"}}> Hands</span><span style={{color: "#389238", fontWeight: "900"}}>OFF</span></h1>
                    <h2 style={{fontWeight: "400"}}>La tua protezione, nelle tue mani</h2>
                   
                    
                    <div style={{borderTop: "5px solid #F2F1E5", marginTop: "20px"}}>
                        <div className="mb-2"><CountdownComponent targetDate={targetDate} /></div>
                        <NewsletterComponent />
                        <a href="https://play.google.com/store/apps/details?id=gesic.handsoff"><img style={{height:"50px", marginRight: "5px"}} src={googlePlay}></img></a>
                        <img style={{height:"50px"}} src={AppStore}></img>
                        <p style={{fontSize: "0.9em"}}>*La versione per App Store sarà disponibile a breve</p>
                    </div>
                    
                </div>
            </div>
        </div>
        <section id="use_case" className="container-fluid">
            <div className="row">
                <div className="col-lg-12">
                    <UseCaseSectionComponent />
                </div>
            </div>
        </section>

        <section id="pricing" className="container mt-5">
            <div className="row">
                <div className="col-lg-12">
                    <PricingComponent />
                </div>
            </div>
        </section>
       
        </>
      
    );
    };

    export default HomePage;