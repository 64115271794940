import React, { useState } from 'react';

const NewsletterComponent: React.FC = () => {
    const [email, setEmail] = useState('');

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const formData = new URLSearchParams();
        formData.append('name', 'HandsOff Newsletter');
        formData.append('email', email);
        formData.append('text', 'HandsOff Newsletter');
        
        try {
            const response = await fetch('https://handsoff.agesic.com/contact.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                },
                body: formData.toString(),
            });

            if (response.ok) {
                setEmail('');
            } else {
                // Handle error response
            }
        } catch (error) {
            // Handle network error
        }
    };

    return (
        <form onSubmit={handleSubmit} style={{display: "flex", justifyContent: "center", marginBottom: "2rem"}}>
            <input type="email" className="form-control me-3" value={email} onChange={handleEmailChange} placeholder="Inserisci la tua email" style={{ width: "50%" }} />
            <button type="submit" className="btn custom_button yellow">Iscriviti</button>
        </form>
    );
};

export default NewsletterComponent;

