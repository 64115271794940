import React from "react";
import SharedIcon from "../imeges/share_icon.svg";
import move from "../imeges/bend_move_round.svg";
import CheckIcon from "../imeges/check_icon.svg";
import IntestazioneSectionComponent from "./IntestazioneSectionComponent";
import { Link } from "react-router-dom";

const PricingComponent = () => {


   
    
    return (
        <>
        
        <div className="container mt-5">
                <div className="row">
                    <div className="col-12">
                        <IntestazioneSectionComponent title="Prezzi" />
                    </div>
                </div>
            </div>
            
        <div className="row justify-content-evenly">
            <div className="col-xl-4 mt-5">
                <div className="pricing_card">
                    <div className="card_header bg_yellow">
                        <div className="text_container">
                        <h4 className="title">HandsOff App</h4>
                        <a href="#" title="Condividi"><img src={SharedIcon} alt="" className="img-fluid"/></a>
                        </div>
                        
                    </div>
                    <div className="card_content">
                        <div className="price_block">
                            <span className="price">GRATUITA</span>
                            
                        </div>
                        <div className="pack_info text-center">
                            <div className="info_header">
                                <div className="text_container">
                                    <h4 className="title">Il piano include:</h4>
                                </div>
                        
                            </div>
                            <div className="info_list">
                                <ul>
                                    <li className="info_item"><img src={CheckIcon}/><span>Allarme vocale</span></li>
                                    <li className="info_item"><img src={CheckIcon}/><span>Allarme manuale</span></li>
                                    <li className="info_item"><img src={CheckIcon}/><span>Contatti fidati illimitati</span></li>
                                    <li className="info_item"><img src={CheckIcon}/><span>Funzione "Seguimi"</span></li>
                                    <li className="info_item"><img src={CheckIcon}/><span>Avvisi</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="card_footer">
                        <button type="button" className="btn custom_button yellow">Ottieni ora</button>
                    </div>
                </div>
            </div>
            <div className="col-xl-4 mt-5">
            <div className="pricing_card">
                    <div className="card_header bg_blue">
                        <div className="text_container">
                        <h4 className="title">Abbonamento mOve</h4>
                        <a title="Condividi" href="#"><img src={SharedIcon} alt="" className="img-fluid"/></a>
                        </div>
                        
                    </div>
                    <div className="card_content">
                        <div className="price_block">
                        <span>a soli </span><span className="price">€ 72,00</span><span>/anno</span>
                            
                        </div>
                        <div className="pack_info text-center">
                            <div className="info_header">
                                <div className="text_container">
                                    <h4 className="title">Il piano include:</h4>
                                </div>
                        
                            </div>
                            <div className="info_list">
                                <ul>
                                    <li className="info_item"><img src={CheckIcon}/><span>Dispositivo indossabile</span></li>
                                    <li className="info_item"><img src={CheckIcon}/><span>Pulsante antipanico</span></li>
                                    <li className="info_item"><img src={CheckIcon}/><span>Non ha bisogno di ricarica</span></li>
                                    <li className="info_item"><img src={CheckIcon}/><span>Cinturino in silicone</span></li>
                                    <li className="info_item"><img src={CheckIcon}/><span>Impermeabile (<strong>IP68</strong>)</span></li>
                                    
                                    
                                </ul>
                            </div>
                            <img alt="aGesic mOve con bracciale" width="180px" src={move}/>
                        </div>
                    </div>
                    <div className="card_footer">
                    
                    
                        <a title="Ottieni maggiori informazioni su aGesic mOve" href="/move" type="button" className="btn custom_button yellow">Maggiori informazioni</a>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
    };
    export default PricingComponent;