import React from "react";
import video from "../imeges/bankomat_video.mp4";
import videoDonna from "../imeges/donna_sola_notte.mp4";
import videoBullismo from "../imeges/bullismo.mp4";
import videoRunning from "../imeges/night_running.mp4";
import CardComponent from "./CardComponent";
import micIcon from '../imeges/mic_icon.svg'
import Move from './../imeges/bend_move_round.svg'
import notifyIcon from '../imeges/notify_icon.svg'
import pinIcon from '../imeges/pin_icon.svg'
import alertIcon from '../imeges/alert_icon.svg'
import IntestazioneSectionComponent from "./IntestazioneSectionComponent";



const UseCaseSectionComponent = () => {


    const AllarmiContent = (
        <>
        <p>Sei in pericolo? <br></br>Un tocco e sei al sicuro! Con il <strong>pulsante SOS</strong> invii immediatamente l’allarme ai
contatti che hai impostato. Temi per la tua sicurezza? Con <strong>l’allarme vocale</strong>, basterà gridare la parola
AIUTO*! per avviare la procedura di emergenza e per attivare un segnale sonoro per dissuadere
l’aggressore.</p>
<em>*La funzione deve essere attivata all’interno dell’APP.</em>
       
        </>
    );

    const AvvisiContent = (
        <>
        <p>Rimani connesso con i tuoi contatti di fiducia grazie agli avvisi in tempo reale! Hai il controllo totale:
puoi facilmente personalizzare e aggiornare i tuoi contatti di emergenza in qualsiasi momento. Invia
istantaneamente avvisi tramite chiamata, SMS ed email, per un soccorso tempestivo in qualsiasi
situazione.</p>

       
        </>
    );

    const GeolocalizzazioneContent = (
        <>
        <p>Grazie alla <strong>geolocalizzazione</strong>, aGesic HandsOFF invia una segnalazione completa dei dati di posizione,
consentendo ai tuoi contatti di localizzarti rapidamente, ovunque tu sia. Con la funzione <strong>SEGUIMI</strong>,
avvii il tracciamento della tua posizione in tempo reale. La tua <strong>privacy</strong> è sempre garantita: la
geolocalizzazione si attiva solo in caso di richiesta di aiuto.</p>

       
        </>
    );

    const MoveContent = (
        <>
        <p>Grazie a questo piccolissimo Device IoT, che dialoga con l’app sullo smartphone, puoi indossare la tua
sicurezza anche quando il telefono non è a portata di mano. aGesic mOve, discreto e portatile, si
integra perfettamente nel tuo stile di vita.</p>

       
        </>
    );
    
    return (
        <div className="container_use_case_section">
            <div className="use_case_section gradient">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 align-self-center">
                            <div style={{width: "400px", height: "400px", borderRadius: "50%", overflow: "hidden", border:"20px solid #DF2A26" }}><video style={{marginLeft: "-165px"}} height="400px" autoPlay muted loop ><source src={video} /></video></div>
                    
                        </div>
                        <div className="col-lg-6 align-self-center">
                            <div className="d-flex align-content-center">
                            <h2 style={{fontWeight: "900"}}>aGesic HandsOFF:</h2>
                            </div>
                        
                            <p className="text-justify"><strong>è l’alleato della tua protezione personale!</strong><br></br>Combinando un'APP all'avanguardia a un dispositivo IoT ultracompatto, aGesic HandsOFF
accompagna chi è solo e a rischio, offrendo un sistema completo di allarme SOS, allarme
vocale, geolocalizzazione in caso di pericolo e invio di allarmi ai contatti di cui ti fidi.<br></br><br></br><strong>aGesic potenzia la tua sicurezza individuale, garantendo risposte immediate in ogni
emergenza.</strong></p>
                        </div>
                    </div>
                </div>

            <div className="container mt-5">
                <div className="row">
                    <div className="col-lg-6 align-self-center">
                        <div style={{width: "400px", height: "400px", borderRadius: "50%", overflow: "hidden", border:"20px solid #DF2A26" }}><video style={{marginLeft: "-50px"}} height="400px" autoPlay muted loop ><source src={videoDonna} /></video></div>
                    
                    </div>
                    <div className="col-lg-6 align-self-center">
                        <div className="d-flex align-content-center">
                        <img src={alertIcon} height="30px"/><h2 style={{fontWeight: "900"}}>Donne e ragazze</h2>
                        </div>
                        
                        <p className="text-justify"><strong>Ti sei mai sentita insicura mentre cammini da sola?</strong><br></br>
                        Non lasciare che la paura ti paralizzi: <stong>la tua libertà di sentirti al sicuro</stong> è un diritto fondamentale!
HandsOFF è la tua voce silenziosa e discreta: grazie alla <strong>geolocalizzazione</strong>, attiva solo in caso di
richiesta di aiuto e alle funzioni di <strong>allarme immediato</strong>, non sarai mai sola nelle situazioni difficili.
Immagina di poter inviare rapidamente una richiesta di aiuto con un semplice tocco o gridando la
parola AIUTO!*. Affidati a HandsOFF e muoviti in sicurezza e libertà!</p><em>*La funzione deve essere attivata all’interno dell’APP.</em>
                    </div>
                </div>
            </div>

            <div className="container mt-5">
                <div className="row">
                    <div className="col-lg-6 align-self-center">
                        <div style={{width: "400px", height: "400px", borderRadius: "50%", overflow: "hidden", border:"20px solid #DF2A26" }}><video style={{marginLeft: "-150px"}} height="400px" autoPlay muted loop ><source src={videoBullismo} /></video></div>
                    
                    </div>
                    <div className="col-lg-6 align-self-center">
                        <div className="d-flex align-content-center">
                        <img src={alertIcon} height="30px"/><h2 style={{fontWeight: "900"}}>Adolescenti e giovani</h2>
                        </div>
                        
                        <p className="text-justify"><strong>Come ti sentiresti se tua figlia o tuo figlio si trovasse in una situazione di pericolo, senza un modo rapido per chiamare aiuto?</strong><br></br>
                       
Con un'applicazione intuitiva e un dispositivo IoT ultracompatto, ora puoi dormire sonni tranquilli
sapendo che i tuoi cari sono protetti. Testato e comprovato, aGesic Hands Off offre funzionalità di
<strong>sicurezza</strong> affidabili e immediate. Con HandsOFF la <strong>protezione</strong> della tua famiglia è nelle tue mani!</p>
                    </div>
                </div>
            </div>

           
            <div className="container mt-5">
                <div className="row">
                    <div className="col-12">
                        <IntestazioneSectionComponent title="Funzioni" />
                    </div>
                </div>
            </div>

            <div className="container mt-5">
            
                <div className="row">
                    <div className="col-lg-3 mt-4">
                        <CardComponent title="Allarmi" imgsrc={micIcon} show_link={false} content={AllarmiContent} />
                    </div>
                    <div className="col-lg-3 mt-4">
                        <CardComponent title="Avvisi" imgsrc={notifyIcon} show_link={false} content={AvvisiContent} />
                    </div>
                    <div className="col-lg-3 mt-4">
                        <CardComponent title="Geolocalizzazione" imgsrc={pinIcon} show_link={false} content={GeolocalizzazioneContent}/>
                    </div>
                    <div className="col-lg-3 mt-4">
                        <CardComponent title="aGesic mOve" imgsrc={Move} url="/move"  content={MoveContent}/>
                    </div>
                </div>
            </div>
            
                
            </div>
        </div>
        
    );
    };
    export default UseCaseSectionComponent;